import React from "react";
import Plot from "react-plotly.js";

const AirQPlot = ({ weather }) => {
  const getAirqPlotData = () => {
    const items = Object.values(Object.values(weather)[0])
      .filter((item) => item.airQuality && item.airQuality.aqi)
      .map((item) => item.airQuality.aqi);
    const dates = Object.entries(Object.values(weather)[0])
      .filter((item) => item[1].airQuality && item[1].airQuality.iaqi)
      .map((item) => item[0]);
    const traces = [
      {
        x: dates.map((item) => new Date(parseInt(item))),
        y: items,
        mode: "lines+markers",
        line: { shape: "spline", color: "#91f086" },
        type: "scatter",
      },
    ];

    return traces;
  };

  const layout = {
    autosize: false,
    width: document.querySelector(".data-container").clientWidth * 0.7,
    height: document.querySelector(".data-container").clientWidth * 0.4,
  };

  return <Plot data={getAirqPlotData()} layout={layout}></Plot>;
};

export default AirQPlot;
