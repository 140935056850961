import React,{ useState, useEffect } from 'react';
import times from 'lodash/times';
import TempPlot from './TempPlot'
import groupBy from 'lodash/groupBy';
import Plotly from 'plotly.js-dist';
import Plot from 'react-plotly.js';

const WindPlot = ({weather}) => {

    const getWindPlotData = () => {
		const items = Object.values(Object.values(weather)[0])
		
		let wind_deg = items.map(item => item.wind ? item.wind.deg : item.wind_deg)
		let wind_speed = items.map(item => item.wind ? item.wind.speed : item.wind_speed)
		let pressure = items.map(item => item.pressure);

		const dates = Object.keys(Object.values(weather)[0])

        const dts = dates.map(it => new Date(parseInt(it)))

        const formatted = dts.map(it => `${it.getDate()}/${it.getMonth() > 10 ? it.getMonth() : `0${it.getMonth() + 1}`}`)

		const size = Math.min(wind_deg.length, wind_speed.length, pressure.length)

		wind_deg.length = size;
		wind_speed.length = size;
		pressure.length = size;

			const traces = [{
				x: dates.map(item => new Date(parseInt(item))),
				y: wind_deg,
				mode: 'lines+markers',
				name: 'spline',
				line: {shape: 'spline', width: 2, color: '#3892c6'},
				marker: { size: 4 },
				hoverinfo: 'text',
				text: wind_deg.map(it => `Wind Degree ${it}`),
				type: 'scatter',
				showLegend: false,
			  }, {
				x: dates.map(item => new Date(parseInt(item))),
				y: wind_speed,
				mode: 'lines+markers',
				name: 'spline',
				marker: { size: 4 },
				hoverinfo: 'text',
				text: wind_speed.map(it => `Wind Speed ${it}`),
				line: {shape: 'spline', width: 2, color: "#d7e9f5"},
				type: 'scatter'
			  },
			  {
				x: dates.map(item => new Date(parseInt(item))),
				y: pressure,
				mode: 'lines+markers',
				name: 'spline',
				hoverinfo: 'text',
				marker: { size: 4 },
				text: pressure.map(it => `Pressure ${it}`),
				line: {shape: 'spline', width: 2, color: '#7fbadc'},
				type: 'scatter'
			  }]
	
	
			return traces;	
	}


	const layout = {
		autosize: false,
		width: document.querySelector('.data-container').clientWidth * 0.7,
		height: document.querySelector('.data-container').clientWidth * 0.4,
	  };

	return <Plot data={getWindPlotData()} layout={layout}/>
}

export default WindPlot