/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import GlobeDropdown from "./GlobeDropdown";
import CountriesDropdown from "./CountriesDropdown";
import DataNav from './DataNav';
import DownloadButton from './DownloadButton'
import Guide from './Guide';


import {dataTabs, getVizByTabName} from './dataTabs';
import { saveJson, saveChart, saveTxt } from './savers';


import loader from "./img/loader.svg";

const Data = ({
  countries,
  chooseGlobeView,
  globeView,
  chooseCountry,
  data,
  country,
  url,
  applyBg,
  countryWeather,
  countryCovid
}) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isGuideVisible, setIsGuideVisible] = useState(!localStorage.getItem('guided'));
  const [activeTab, setActiveTab] = useState(dataTabs[0]);

  useEffect(() => {
    return () => chooseCountry('')
  }, [])

  useEffect(() => {
    if (!countryCovid && !countryWeather) {
      return;
    }


    setIsLoaderVisible(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryWeather, countryCovid]);

  useEffect(() => {
    if(!country){ return }
    setIsLoaderVisible(true);
  }, [country])


  const closeGuide = () => {
    localStorage.setItem('guided', 'true');
    setIsGuideVisible(false)
  }

  const isCovidDataEmpty = activeTab.tabName === "COVID-19" && countryCovid && countryCovid.confirmed && countryCovid.confirmed.length === 0


  return (
    <>
      { isGuideVisible && (<div id="guide-wrapper"><Guide onClose={closeGuide}/></div>)}     
      {activeTab && (<div id='data'>
        <div className='panel'>
         <DataNav covid={countryCovid} weather={countryWeather} activeTab={activeTab} activateTab={setActiveTab} url={url} applyBg={applyBg} isWeatherAvailable={Boolean(countryWeather)} isCovidAvailable={Boolean(countryCovid && countryCovid.confirmed)}/>
         {((countryCovid && countryCovid.confirmed) || countryWeather) && 
          <>
            <DownloadButton tab={activeTab.tabName} 
              country={country} 
              saveJson={(exact) => saveJson(exact, activeTab, country, countryCovid, countryWeather)}  
              isWeatherAvailable={Boolean(countryWeather)} 
              isCovidAvailable={Boolean(countryCovid && countryCovid.confirmed)} 
              saveTxt={(exact) => saveTxt(exact, activeTab, country, countryCovid, countryWeather)} 
              saveChart={saveChart} 
              isCovidDataEmpty={isCovidDataEmpty}/>
          </>
         }
        </div>
        <div className='data-container'>
          <div id='info'>
            <div>
              <div className='title'>{country && activeTab.tabName !== "GLOBE" ? `${activeTab.title} OF ${country.toUpperCase()}` : activeTab.title }</div>
              <div className='description'>{activeTab.description}</div>
            </div>

            <>{activeTab.tabName === "GLOBE" ? (
              <GlobeDropdown view={globeView} chooseGlobeView={chooseGlobeView}/> ) : ( <CountriesDropdown country={country} countries={countries} chooseCountry={chooseCountry}/>)
							}
						</>
          </div>
          {activeTab.tabName === "GLOBE" && (<div id='vizualisation'>{getVizByTabName(activeTab.tabName, url)}</div>)}
          {(activeTab.tabName === "COVID-19" && countryCovid && countryCovid.confirmed && countryCovid.confirmed.length > 0) && (<div id='vizualisation'>{getVizByTabName(activeTab.tabName, countryCovid)}</div>)}
          {(isCovidDataEmpty) && (<div className="empty-state">COVID-19 DATA IS NOT AVAILABLE FOR THIS REGION</div>)}
          {(isLoaderVisible && activeTab.tabName !== "GLOBE") && (<img src={loader} style={{position: 'absolute', top: '40%', right: '20%'}} alt="loader"/>)}
          {(activeTab.tabName !== "GLOBE" && activeTab.tabName !== "COVID-19" && countryWeather)  && (<div id='vizualisation'>{getVizByTabName(activeTab.tabName, countryWeather)}</div>)}
        </div>
      </div>
      )}
      </>
  );
};

export default Data;