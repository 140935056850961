import { saveAs } from "file-saver";
import JSZip from 'jszip';

import { getDataList, getAllDataList } from './scripts';

export const saveJson = (exact, activeTab, country, countryCovid, countryWeather) => {
    const fileName = exact ? `${activeTab.title}-${country}-data.json` : `${country}-data.json`;
    
    const info = exact ? activeTab.dataProperty.map(property => getDataList(property, {covid: countryCovid, weather: countryWeather})) : getAllDataList({covid: countryCovid, weather: countryWeather});


	const isCovid = (activeTab.tabName === "COVID-19") && exact
	const isWeather = (activeTab.tabName === "WEATHER") && exact

	if(!exact){
		saveAllJson(info, country);
		return;
	}

    if(isCovid) {
      saveCovidJson(info, activeTab, country);
      return;
	}
	
	if(isWeather) {
		saveWeatherJson(info.filter(arr => arr.length > 0), activeTab, country);
		return;
	  }

		const blob = new Blob([JSON.stringify(info)], {type: "application/json"});

		saveAs(blob, fileName);
  };

  const saveAllJson = (info, country) => {
	const nzip = new JSZip();


	info.forEach(inf => {
		const name = Object.keys(inf)[0];
		if(name === "wind"){
			nzip.file(`wind-deg-data.json`, new Blob([inf.wind.deg], {type: "application/json"}))
			nzip.file(`wind-speed-data.json`, new Blob([inf.wind.speed], {type: "application/json"}))
			return;
		}
		if(name === "covid"){
			nzip.file(`covid-confirmed-data.json`, new Blob([JSON.stringify(inf.covid[0])], {type: "application/json"}));
    		nzip.file(`covid-dead-data.json`, new Blob([JSON.stringify(inf.covid[1])], {type: "application/json"}));
    		nzip.file(`covid-recovered-data.json`, new Blob([JSON.stringify(inf.covid[2])], {type: "application/json"}));
			return;
		}

		nzip.file(`${name}-data.json`, new Blob([JSON.stringify(inf[name])], {type: "application/json"}))
	})


	nzip.generateAsync({type:"blob"}).then(function(content) {
		saveAs(content, `${country}-json-data.zip`);
	  });
  }

  const saveAllTxt = (info, country) => {
	const nzip = new JSZip();


	info.forEach(inf => {
		const name = Object.keys(inf)[0];
		if(name === "wind"){
			nzip.file(`wind-deg-data.txt`, new Blob([JSON.stringify(inf.wind.deg).replace('[', '').replace(']', '')], {type: "text/plain"}))
			nzip.file(`wind-speed-data.txt`, new Blob([JSON.stringify(inf.wind.speed).replace('[', '').replace(']', '')], {type: "text/plain"}))
			return;
		}
		if(name === "covid"){
			nzip.file(`covid-confirmed-data.txt`, new Blob([JSON.stringify(inf.covid[0]).replace('[', '').replace(']', '')], {type: "text/plain"}));
    		nzip.file(`covid-dead-data.txt`, new Blob([JSON.stringify(inf.covid[1]).replace('[', '').replace(']', '')], {type: "text/plain"}));
    		nzip.file(`covid-recovered-data.txt`, new Blob([JSON.stringify(inf.covid[2]).replace('[', '').replace(']', '')], {type: "text/plain"}));
			return;
		}

		nzip.file(`${name}-data.txt`, new Blob([JSON.stringify(inf[name]).replace('[', '').replace(']', '')], {type: "text/plain"}))
	})


	nzip.generateAsync({type:"blob"}).then(function(content) {
		saveAs(content, `${country}-txt-data.zip`);
	  });
  }
  
  export const saveWeatherJson = (arr, activeTab, country,) => {
    let nzip = new JSZip();
	const zip = getWeatherJsonZip(nzip, arr, activeTab)


    zip.generateAsync({type:"blob"}).then(function(content) {
      saveAs(content, `${activeTab.tabName}-${country}-json-data.zip`);
    });
  }

  const getWeatherJsonZip = (zip, arr, activeTab) => {
	const getName = (item) => Object.keys(item[0])[0]
	const getNumbers = item => {
		const name = getName(item);

		return item.map(i => i[name]).flat(1);
	}

    arr[0] && (zip.file(`${activeTab.tabName}-${getName(arr[0])}-data.json`, new Blob([JSON.stringify(getNumbers(arr[0]))], {type: "application/json"})));
    arr[1] && (zip.file(`${activeTab.tabName}-${getName(arr[1])}-data.json`, new Blob([JSON.stringify(getNumbers(arr[1]))], {type: "application/json"})));
	arr[2] && (zip.file(`${activeTab.tabName}-${getName(arr[2])}-data.json`, new Blob([JSON.stringify(getNumbers(arr[2]))], {type: "application/json"})));
	arr[3] && (zip.file(`${activeTab.tabName}-${getName(arr[3])}-data.json`, new Blob([JSON.stringify(getNumbers(arr[3]))], {type: "application/json"})));

	return zip
  }

  export const saveCovidJson = (arr, activeTab, country,) => {
    let nzip = new JSZip();
	const zip = getCovidJsonZip(nzip, arr, activeTab, country)

    zip.generateAsync({type:"blob"}).then(function(content) {
      saveAs(content, `${activeTab.tabName}-${country}-json-data.zip`);
    });
  }

  const getCovidJsonZip = (zip, arr, activeTab, country) => {
	const n = [ "confirmed", "dead", "recovered" ]

	zip.file(`${activeTab.tabName}-${n[0]}-${country}-data.json`, new Blob([JSON.stringify(arr[0][0])], {type: "application/json"}));
    zip.file(`${activeTab.tabName}-${n[1]}-${country}-data.json`, new Blob([JSON.stringify(arr[0][1])], {type: "application/json"}));
    zip.file(`${activeTab.tabName}-${n[2]}-${country}-data.json`, new Blob([JSON.stringify(arr[0][2])], {type: "application/json"}));
	
	return zip
  }

  export const saveCovidTxt = (arr, activeTab, country) => {
	let nzip = new JSZip();
	const zip = getCovidTxtZip(nzip, arr, activeTab, country)

    zip.generateAsync({type:"blob"}).then(function(content) {
      saveAs(content, `${activeTab.tabName}-${country}-txt-data.zip`);
    });
  }

  const getCovidTxtZip = (zip, arr, activeTab, country) => {
	const n = [ "confirmed", "dead", "recovered" ]

	zip.file(`${activeTab.tabName}-${n[0]}-${country}-data.txt`, new Blob([JSON.stringify(arr[0][0]).replace('[', '').replace(']', '')], {type: "text/plain"}));
    zip.file(`${activeTab.tabName}-${n[1]}-${country}-data.txt`, new Blob([JSON.stringify(arr[0][1]).replace('[', '').replace(']', '')], {type: "text/plain"}));
	zip.file(`${activeTab.tabName}-${n[2]}-${country}-data.txt`, new Blob([JSON.stringify(arr[0][2]).replace('[', '').replace(']', '')], {type: "text/plain"}));
	
	return zip
  }

  export const saveWeatherTxt = (arr, activeTab, country) => {
	let nzip = new JSZip();
	
	const zip = getTxtWeatherZip(nzip, arr, activeTab);

    zip.generateAsync({type:"blob"}).then(function(content) {
      saveAs(content, `${activeTab.tabName}-${country}-txt-data.zip`);
    });
  }

  const getTxtWeatherZip = (zip, arr, activeTab) => {
	const getName = (item) => Object.keys(item[0])[0]
	const getNumbers = item => {
		const name = getName(item);

		return item.map(i => i[name]).flat(1);
	}

    arr[0] && (zip.file(`${activeTab.tabName}-${getName(arr[0])}-data.txt`, new Blob([JSON.stringify(getNumbers(arr[0])).replace('[', '').replace(']', '')], {type: "text/plain"})));
    arr[1] && (zip.file(`${activeTab.tabName}-${getName(arr[1])}-data.txt`, new Blob([JSON.stringify(getNumbers(arr[1])).replace('[', '').replace(']', '')], {type: "text/plain"})));
	arr[2] && (zip.file(`${activeTab.tabName}-${getName(arr[2])}-data.txt`, new Blob([JSON.stringify(getNumbers(arr[2])).replace('[', '').replace(']', '')], {type: "text/plain"})));
	arr[3] && (zip.file(`${activeTab.tabName}-${getName(arr[3])}-data.txt`, new Blob([JSON.stringify(getNumbers(arr[3])).replace('[', '').replace(']', '')], {type: "text/plain"})));

	return zip
  }

  export const saveTxt = (exact, activeTab, country, countryCovid, countryWeather)  => {
    const fileName = exact ? `${activeTab.title}-${country}-data.txt` : `${country}-data.txt`;
    
    const info = exact ? activeTab.dataProperty.map(property => getDataList(property, {covid: countryCovid, weather: countryWeather})) : getAllDataList({covid: countryCovid, weather: countryWeather});
	const i = exact && activeTab.tabName === 'TEMPERATURE' ? info[0].map(({temp}) => temp) : info;
	
	const isCovid = (activeTab.tabName === "COVID-19") && exact
	const isWeather = (activeTab.tabName === "WEATHER") && exact


	if(!exact){
		saveAllTxt(i, country)
		return;
	}

    if(isCovid) {
		saveCovidTxt(i, activeTab, country);
		return;
	  }
	  
	  if(isWeather) {
		  saveWeatherTxt(i.filter(arr => arr.length > 0), activeTab, country);
		  return;
		}

	const blob = new Blob([JSON.stringify(i).replace('[', '').replace(']', '')], {type: "text/plain"});

	saveAs(blob, fileName);
  };

  export const saveChart = () => {
    document.querySelector('[data-title="Download plot as a png"]').click()
  }