import React from "react";

import data from "./works-data";
import "./index.css";

const Works = () => {
  return (
    <div
      className="lectures"
      style={{
        color: "white",
        textTransform: "uppercase",
        textAlign: "justify",
        fontWeight: "100",
      }}
    >
      {data.map((item) => (
        <div style={{ position: "relative" }}>
          <div>{item.text}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {item.title}
          </div>

          <div className="videoContainer">{item.video.map((i) => i)}</div>
        </div>
      ))}
    </div>
  );
};

export default Works;
