import React from 'react';

import TempPlot from "./charts/TempPlot";
import CovidPlot from "./charts/CovidPlot";
import WindPlot from "./charts/WindPlot";
import AirQPlot from "./charts/AirQPlot";
import earth from "./img/GLOBE.png";
import airBg from "./img/AIR.png";
import tempBg from "./img/TEMP.png";
import weatherBg from "./img/WEATHER.png";
import covidBg from "./img/COVID.png";

export const dataTabs = [
	{
	  tabName: "COVID-19",
	  param: 'covid',
	  title: "COVID-19 STATISTICS",
	  description: "Interactive 3D chart that gives you an insight on COVID-19 spread in the capital region of chosen country.",
	  bg: covidBg,
	  oscProperties: ['covid'],
	  dataProperty: ['covid']
	},
	{
	  tabName: "TEMPERATURE",
	  title: "TEMPERATURE",
	  param: 'temperature',
	  description: "See how temperature changed through the time of the pandemic in the capital region of chosen country.",
			bg: tempBg,
			oscProperties: ['temp', 'humidity'],
			dataProperty: ['temp']
	},
	{
	  tabName: "AIR QUALITY",
	  title: "AIR QUALITY",
	  param: 'air-quality',
	  description: "See how air quality changed through the time of the pandemic in the capital region of chosen country.",
			bg: airBg,
			oscProperties: ['airQuality'],
			dataProperty: ['airQuality']
	},
	{
	  tabName: "WEATHER",
	  title: "WEATHER",
	  param: 'weather',
	  description: "See how the different weather parameters changed through the time of the pandemic in the capital region of chosen country.",
			bg: weatherBg,
			oscProperties: ['wind_deg', 'wind_speed'],
			dataProperty: ['wind_deg', 'wind_speed', 'pressure', "humidity"]
	},
		{
	  tabName: "GLOBE",
	  title: "REALTIME EARTH PROJECTION",
	  description: <>Interactive globe projection by {<a style={{color: "white", textDecoration: 'none'}} href="https://earth.nullschool.net/">EarthWindMap</a>} that allows you to track listed changes in the real time everywhere on earth</>,
	  bg: earth,
	},
  ];

export const getVizByTabName = (tabName, data) => {
	let viz;

	switch(tabName){
		case "COVID-19": {
			viz = <CovidPlot covid={data} />;
			break;
		}
		case "TEMPERATURE": {
			viz = <TempPlot weather={data} />;
			break;
		}
		case "WEATHER": {
			viz = <WindPlot weather={data} />;
			break;
		}
		case "AIR QUALITY": {
			viz = <AirQPlot weather={data} />;
			break;
		}
		case "GLOBE": {
			viz = <iframe
						title='map'
						src={data}
						style={{
						border: "0px #ffffff none",
						height: "100%",
						width: "100%",
						}}
						name='myiFrame'
						scrolling='no'
						frameBorder='1'
						marginHeight='0px'
						marginWidth='0px'
						allowFullScreen
					></iframe>;
			break;
		}
		default: break;
	}

	return viz;
}