import React, { useRef } from 'react';
import TempPlot from './TempPlot'
import groupBy from 'lodash/groupBy';
import Plotly from 'plotly.js-dist';
import Plot from 'react-plotly.js';

const CovidPlot = ({covid}) => {
	const plotRef = useRef(null);
    const getCovidPlotData = () => {
		const { confirmed, recovered, death } = covid;
		
		const dates = confirmed.map(item => new Date(Date.parse(item.Date)))
        
        const confirmedCount = confirmed.map(item => item.Cases)
        const recoveredCount = recovered.map(item => item.Cases)
        const deathCount = death.map(item => item.Cases)


        return [{
            type: 'scatter3d',
            mode: 'lines',
            x: dates,
            y: confirmedCount,
			z: confirmedCount,
			showlegend: false,
			opacity: 1,
			name: 'Confirmed Cases',
			hoverinfo: 'text',
			text: confirmedCount.map((count, i) => `${dates[i].getDate()}/${dates[i].getMonth() > 10 ? dates[i].getMonth() : `0${dates[i].getMonth() + 1}`} - ${count} confirmed cases`),
            line: {
				shape: 'spline',
                width: 5,
                color: "##53abff",
                colorscale: 'Viridis'}
			},
			{
				type: 'scatter3d',
				mode: 'lines',
				x: dates,
				y: recoveredCount,
				z: recoveredCount,
				showlegend: false,
				opacity: 1,
				name: 'Recovered',
				hoverinfo: 'text',
			text: recoveredCount.map((count, i) => `${dates[i].getDate()}/${dates[i].getMonth() > 10 ? dates[i].getMonth() : `0${dates[i].getMonth() + 1}`} - ${count} people recovered`),
				line: {
					width: 5,
					color: '#a3cc48',
					colorscale: 'Viridis'}
				},
				{
					type: 'scatter3d',
					mode: 'lines',
					x: dates,
					y: deathCount,
					z: deathCount,
					showlegend: false,
					name: 'Dead',
					hoverinfo: 'text',
					text: deathCount.map((count, i) => `${dates[i].getDate()}/${dates[i].getMonth() > 10 ? dates[i].getMonth() : `0${dates[i].getMonth() + 1}`} - ${count} people dead`),
					opacity: 1,
					line: {
						width: 5,
						color: '#b40a60',
						colorscale: 'Viridis'}
					}
		
		]
	}
	
	const layout = {
		autosize: true,
		width: document.querySelector('.data-container').clientWidth * 0.55,
		height: document.querySelector('.data-container').clientWidth * 0.55,
		scene: {
			camera: {
				eye: {x: 1, z: 0.3}
			  }
		  }
	  };



	return <Plot data={getCovidPlotData()} layout={layout} ref={plotRef}/>
}

export default CovidPlot