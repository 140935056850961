import React from 'react';
import Plot from 'react-plotly.js';

const TempPlot = ({weather}) => {
    const getTempPlotData = () => {
        const temp = Object.values(Object.values(weather)[0]).map(item => item.temp)
        const feelsLike = Object.values(Object.values(weather)[0]).map(item => item.temp)
        const dates = Object.keys(Object.values(weather)[0])

        const dts = dates.map(it => new Date(parseInt(it)))

        const formatted = dts.map(it => `${it.getDate()}/${it.getMonth() > 10 ? it.getMonth() : `0${it.getMonth() + 1}`}`)

        return [{
            x: dates,
            y: temp,
            z: feelsLike,
            mode: 'markers',
            type: 'scatter3d',
            hoverinfo: 'text',
            text: temp.map((item, idx) => `${formatted[idx]} - Temperature ${item}`),
            marker: {
              color: 'white',
              size: 10,
              border: '1px solid red'
            }
        },{
            alphahull: 7,
            opacity: 0.1,
            type: 'mesh3d',
            x: dates,
            y: temp,
            z: feelsLike
        }];
    }

     const layout = {
        autosize: false,
        width: document.querySelector('.data-container').clientWidth * 0.55,
		height: document.querySelector('.data-container').clientWidth * 0.55,
        scene: {
            aspectratio: {
                x: 1,
                y: 1,
                z: 1
            },
            camera: {
                center: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                eye: {
                    x: 1.25,
                    y: 1.25,
                    z: 0.3
                },
                up: {
                    x: 0,
                    y: 0,
                    z: 1
                }
            },
            xaxis: {
                showLegend: false,
                type: 'linear',
                zeroline: false
            },
            yaxis: {
                type: 'linear',
                zeroline: false
            },
            zaxis: {
                type: 'linear',
                zeroline: false
            }
        },
    };

	return <Plot data={getTempPlotData()} layout={layout}/>
}

export default TempPlot