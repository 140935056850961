import React from "react";

import Navigation from "./navigation";
import logo from "./img/logo.png";
import { useHistory } from "react-router-dom";

const Header = ({ onSelect, selectedTab }) => {
  const history = useHistory();

  return (
    <div id="header">
      <img
        src={logo}
        alt="logo"
        style={{ zIndex: 100 }}
        onClick={() => history.push("/main")}
      />

      <Navigation
        onSelect={onSelect}
        selectedTab={selectedTab}
        selectTab={onSelect}
      />
    </div>
  );
};
export default Header;
