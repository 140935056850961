import React from "react";

import i1 from "./img/1.png";
import i2 from "./img/2.jpg";
import i3 from "./img/3.jpg";
import marek from "./img/marek.jpg";
import grabowsky from "./img/grabowsky.jpg";
import georgiy from "./img/georgiy.jpg";
import lis from "./img/lis.jpg";
import "./index.css";

export default [
  {
    text: (
      <div>
        <h1 className="lname">1. Leonid Hrabovsky</h1>
        <h2>Theme:</h2>{" "}
        <div>
          <img className="lecturerImg" src={grabowsky} alt="grabowsky" />
          Methods of algorithmic composition in the works of composers and sound
          artists. Leonid Hrabovsky(NYC), Georgy Potopalskiy (Kyiv) Introductory
          lecture of the cycle. We will get acquainted with the methods of
          algorithmic composition of ukrainian prominent composer Leonid
          Hrabovsky, who since the 60s of XX century has been working on
          developing his own method of composition. The second part of the
          lecture will discuss modern approaches in the application of
          algorithms in electroacoustic music and sound art.
          <h2 className="bioSection">Bio:</h2> Leonid Hrabovsky (b. 1935) – lead
          of the Ukrainian avant-gard group of the 60-is, that called “Kyiv
          Avant-Garde”, whose work unfolds in a vector of emphasized modern and
          structuralist compositional techniques. In 1960-s Leonid Hrabovsky
          starts the development of his own system of algorithmic composition.
          First examples of his usage of this system are in this pieces:
          «Homöomorphie I–IV» (1968- 1970), «Ornaments», (1969) and «Concerto
          Misterioso» (1977). In 1995 – 2015 he developing his own software for
          algorithmic composition, that would meet the needs of his composition
          method. His newest works, were created with computer, features the
          signs of his style of the 60-90-is, and also new characteristics of
          generative music.{" "}
        </div>
        <h2>
          Additional information:
          <a href="http://www.encyclopediaofukraine.com/display.asp?linkpath=pages%5CH%5CR%5CHrabovskyLeonid.html">
            encyclopediaofukraine
          </a>
          <a href="https://krytyka.com/ua/articles/miy-metod-khronolohiya-formuvannya-tekhniky-y-estetyky">
            krytyka.com
          </a>
        </h2>
        <h2>
          Interview:{" "}
          <a href="https://theclaquers.com/posts/5206?fbclid=IwAR2DEQt7qrLnczE9piOpOTIaoFRXJpVEYlaGJHgeEIklIkeemjpeOSbB_eQ<">
            theclaquers
          </a>{" "}
        </h2>
      </div>
    ),
    video: [
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Rt-9VhhWKWA"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/-e1Oj0kUbeU"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
    ],
  },
  {
    text: (
      <div>
        <h1 className="lname">2. Marek Chołoniewsky</h1>
        <h2>Theme:</h2>{" "}
        <div>
          <img className="lecturerImg" src={marek} alt="marek" />
          Global and NetArt projects Lecture about new kinds of art, established
          in the times of Globalization on society, that utilizes Internet and
          network technologies as essential artistic components.
          <h2>Bio:</h2> Marek Chołoniewsky (b. 1953, Krakow) – Polish composer
          and media artist. Professor of the Krakow Music Academy. Since 2000
          the director of the Electro–acoustic Music Studio at the Academy of
          Music in Kraków and since 2012 the director of Audiosphere Lab at the
          Intermedia Department at the Fine Arts Academy in Krakow. Founder and
          co-founder of many groups and societies: Muzyka Centrum Art Society,
          Freight Train, Studio MCH, DoubleMark, CH&K&K, mc2 duo, Infinity
          Quartett, Kinetic Trio, dizzy kinetics. Choloniewski writes
          instrumental, electroacoustic, music for theater, film and radio,
          author of sound and video installations, audio–visual, outdoor and net
          projects. He received Honorable Award of the Polish Composers Union,
          Award of the Ministry of Culture and National Heritage, as well as the
          Independent Project grant of the CEC ArtsLink in New York. Since 2008
          he is the Secretary, and since 2011 the President of the International
          Confederation of Electroacoustic Music (ICEM/CIME).
        </div>
        <h2>
          Website: <a href="http://www.studiomch.art.pl/">studiomch</a>
        </h2>
        <h2>
          Interview:
          <a href="https://theclaquers.com/en/posts/5562">theclaquers</a>
          <a href="https://theclaquers.com/posts/5556">theclaquers</a>
          <a href="https://theclaquers.com/en/posts/5273">theclaquers</a>
          <a href="https://theclaquers.com/posts/5256">theclaquers</a>
        </h2>
      </div>
    ),

    video: [
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/69H3YtjBIfs"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
    ],
  },
  {
    text: (
      <div>
        <h1 className="lname">3.Georgiy Potopalskiy.</h1>
        <h2>Theme:</h2>{" "}
        <div>
          <img className="lecturerImg" src={georgiy} alt="georgiy" />
          Usage of algorithms in media art At this master class artist will show
          up principles of algorithmic generation of sound and visual (with
          examples of some of his artistic projects). Also he will tell about
          audiovisual algorithms and installations, and his experience with the
          Max/MSP too. At this master class you also could learn about building
          the patch for using the camera as a real time instrument for
          generating audio.
          <h2 className="bioSection">Bio:</h2>
          George Potopalskiy (artistic pseudonym Ujif Notfound) In 2007 he
          started the author's project Ujif Notfound. Member of the Association
          of Electroacoustic Music of Ukraine at the National Union of Composers
          of Ukraine (since 2010) Participates in many festivals in Ukraine.
          Represented Ukraine at concerts of the International Confederation of
          Electroacoustic Music CIME in China, USA, Poland, Austria and others.
          Created a number of media installations. Releases albums. He
          collaborates with many Ukrainian and foreign artists as a media
          artist. Collaborates with producers: Dmitry Fedorenko (founder of the
          label and festival KVITNU <a href="http://kvitnu.com/">kvitnu</a>).
          Actively collaborates with composer Alla Zagaykevych, is a regular
          participant of festivals of the National Union of Composers of Ukraine
          and electronic music projects since 2009 (EM-VISIA and
          Electroacoustics projects).
        </div>
            For two years he was a lecturer    of optional courses in music
        programming at the National Music Academy of Ukraine (Department of
        Composition and Music Information Technologies). Co-founder of the New
        Media Art School with author courses from the most famous media artists
        of Ukraine. (Photinus, BLCKBOX) He is the founder of the art location
        Kontrapunkt - a platform for concerts of modern Ukrainian music of
        current genres: experimental, avant-garde, electroacoustic, new academic
        music, festivals: "EM-vision", "Electroacoustics", "16+".
        <h2>
          Interview:{" "}
          <a href="https://theclaquers.com/posts/5284">theclaquers</a>
          <a href="https://theclaquers.com/en/posts/5306">theclaquers</a>
        </h2>
        <h2>
          Website: <a href="http://un2114.com/">un2114</a>
        </h2>
      </div>
    ),
    video: [
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/BRCu3wSVXvQ"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
    ],
  },
  {
    text: (
      <div>
        <h1 className="lname">4. Ostap Kostiuk, Eugen Vashchenko</h1>
        <div className="bioContainer">
          <img className="imgi1 mgn-r-30" src={i3} alt="Kostiuk" />
          <div>
            <h2>Theme : </h2>{" "}
            <div>
              "Structure, sound and visual matrix in the space of abstract
              parametric media installation", "Entry into electronics: sound of
              frying chips"
            </div>
            <div>- Parametric media installation in public environment.</div>
            <div>
              - Automation of the systems, that generating interactive
              composition.
            </div>
            <div>
              - Creative aspect of adjustment of the automatic harmonic systems.
            </div>
            <div>
              - Spreading the principles of construction of sound composition on
              the visual structure.
            </div>
            <div>
              - Merging the methods of construction of sound and visual
              components into the abstract parametric media installation as
              physical object.
            </div>
          </div>
        </div>
        <h2>Bio:</h2>
        <div className="bioContainer">
          Ostap Kostyuk: Born: 12/06/1988 in Ukraine, Lviv. Currently based in
          Kyiv. Occupation: Sound art, interactive technologies, programming,
          circuit engineering/bending Music participant: Квітень руїн, Sport and
          Music, ДУбы-колДУны, ospik 2020 projects participant: Мистецька алея,
          sound residency V:UNCASE, Silkscreen fest // Muzeon & CoscheyLocalDiy
          & Volno, Ukraine WOW. Ostap Kostyuk Born: 12/06/1988 in Ukraine, Lviv.
          Currently based in Kyiv. Occupation: Sound art, interactive
          technologies, programming, circuit engineering/bending Music
          participant: Квітень руїн, Sport and Music, ДУбы-колДУны, ospik 2020
          projects participant: Мистецька алея, sound residency V:UNCASE,
          Silkscreen fest // Muzeon & CoscheyLocalDiy & Volno, Ukraine WOW.
          <br />
          <br />
          Eugen Vashchenko — artist, that works in the field of algorithmic art
          in sound, sculpture and visual space since 2007. His works realizing
          by manipulations of the programmed algorithms (which autor made by
          himself) in realtime and directly for choosed moment with usage of the
          Max / Msp / Jitter. From 2009 projects of V4W.ENKO was shown at
          several festivals of media (FIBER (Netherlands), Ars Electronica
          (Austria), CynetArt (Germany), Mutek (Canada), Kvitnu (Ukraine),
          NextSound (Ukraine), FILE (Mexico) FF`Space Showcase (Japan) and
          others. Under the pseudonym v4w.enko, Eugene performs and develops
          algorithmic tools, MaxMsp software modules, sounds, object sculptures,
          visual compositions, spatial installations. In 2011 artist founded the
          lable „FF'Space”, and his audiovisual CD V4W.ENKO «Harmonic Ratio» has
          been awarded by „Quartz Awards”.
          <img src={i2} className="lecturerImg imgi2" alt="Vashchenko" />
        </div>
        <h2>
          Interview:
          <a href="https://theclaquers.com/posts/5374">theclaquers</a>
        </h2>
      </div>
    ),
    video: [
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Jj4V2tGn2_M"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
    ],
  },
  {
    text: (
      <div>
        <h1 className="lname">5. Artur Lis</h1>
        <h2>Theme: </h2>
        <div>Methods of the image deformation</div>
        <div>
          <img className="lecturerImg" src={lis} />
          Various techniques for deforming a livestream image using Resolume and
          Touchdesigner. How I created theatrical performance through varius
          media during pandemic lockdown. <h2>Bio:</h2> ARTUR LIS - SET
          DESIGNER, VJ, PERFORMER, CREATES ANIMATIONS, INTERACTIVE & IMMERSIVE
          INSTALLATIONS, MAPPING, EDITING, FILM AND TELEVISION POST-PRODUCTION,
          COMPREHENSIVE "GRANDPA" OF VISUAL ARTS. HE STARTED A CREATIVE
          PROVOCATION WITH MINIMALIST ART IN 2005. ASSOCIATED WITH MANY
          SIGNIFICANT FESTIVALS, BANDS AND INSTITUTIONS. SPEAKER AT
          INTERNATIONAL CONFERENCES ON NEW MEDIA IN ART. CROUCHING DEVELOPER OF
          NEW VISUAL TECHNIQUES, THE PREMIERES OF WHICH CAN OFTEN BE SEEN IN THE
          URBAN UNDERGROUND. NO ART (CONSCIOUSLY VANISHING) MULTIDISCIPLINARY
          “NON-ARTIST” COMBINES GEOMETRIC ARCHITECTURE WITH ORGANIC FLUID AND
          VISUAL PROGRAMMING. HE WORKS ON THE EDGE OF RECOGNITION WITH A
          SUBCONSCIOUS MESSAGE. HIS WORKS HAVE BEEN SHOWN ON SEVERAL CONTINENTS.
          PHD STUDENT AT THE INTERMEDIA DEPARTMENT OF THE KRAKOW ACADEMY OF FINE
          ARTS.
        </div>
        <h2>
          Website: <a href="http://no.art.pl">no.art</a>
        </h2>
        <h2>
          Interview:{" "}
          <a href="https://theclaquers.com/posts/5443">theclaquers</a>
        </h2>
      </div>
    ),
    video: [
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/V00WIqOU2kQ"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/6Islq1Xn7p0"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
    ],
  },
  {
    text: (
      <div>
        <h1 className="lname">6. Piotr Madej Interactivity (un?)limited.</h1>
        <h2>Theme : </h2>{" "}
        <div>
          <img className="lecturerImg mgn-t " src={i1} />
          Limitations of interaction in art projects during a pandemic, and a
          pandemic media space as an opportunity to break some of them{" "}
          <h2>Bio:</h2>
          Piotr Michał Madej Intermedia artist, composer and sound engineer,
          member of Polish Society of Electroacoustic Music, member of artistic
          and scientific research collective group GrupLab, double scholar of
          The Ministry of Culture and National Heritage of Poland in years of
          2017 and 2019, since 2007 associated with Audio Art Fesival, i!
          Gallery, ATA Scene and APTEKA Janicki Gallery, graduate of the
          Intermedia on Academy of Fine Arts in Krakow, now working on a phd
          project in Doctoral School on Academy of Fine Arts in Krakow.
        </div>
        <h2>
          Interview:{" "}
          <a href="https://theclaquers.com/en/posts/5550">theclaquers</a>{" "}
          <a href="https://theclaquers.com/posts/5535">theclaquers</a>
        </h2>
      </div>
    ),
    video: [
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/khpBep85Dd0"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/feQd6-XfZSg"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>,
    ],
  },
];
