import React  from 'react';
import { dataTabs } from './dataTabs';


const DataNav = ({applyBg, activateTab, activeTab, isWeatherAvailable, isCovidAvailable}) => {	
	const isDisabled = tab => tab.tabName === 'COVID-19' ? !isCovidAvailable : !isWeatherAvailable

	
	const onTabClick = tab => {
		if(isDisabled(tab)){ return; }
		activateTab(tab);
		applyBg({view: tab.tabName, bg: tab.bg});	
	}

	const tabClasses = (tab) => {
		const selected = activeTab.tabName === tab.tabName ? "selected" : "";
		const disabled = isDisabled(tab) ? "disabled" : "";

		return `${selected} ${disabled}`;
	}

	return ( 
			<nav id='data-nav'>
				{dataTabs.map((tab) => <span
						className={tabClasses(tab)}
						onClick={() => onTabClick(tab)}
						key={tab.tabName}
					>
						{tab.tabName}
					</span>
				)}
			</nav>
		)}

export default DataNav;