import React,{ useState } from 'react'

const GlobeDropdown = ({chooseGlobeView, view}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [globeView, setGlobeView] = useState(view);


	const onChoose = view => {
		chooseGlobeView(view); 
		setGlobeView(view)
		setIsOpen(false)
	}

	const views = [
		{id: '/#current/wind/surface/level/orthographic', name: 'Wind'},
		{id: '/#current/wind/surface/level/overlay=temp/orthographic', name: 'Temperature'},
		{id: '/#current/wind/surface/level/overlay=relative_humidity/orthographic', name: 'Relative Humidity'},
		{id: '/#current/ocean/primary/waves/overlay=sea_surface_temp/orthographic', name: 'Sea Surface Temperature'},
		{id: '/#current/chem/surface/level/overlay=cosc/orthographic', name: 'Carbon Monoxide Concentration'},
		{id: '/#current/chem/surface/level/overlay=co2sc/orthographic', name: 'Carbon Dioxide Concentration'},
		{id: '/#current/chem/surface/level/overlay=so2smass/orthographic', name: 'Sulfur Dioxide Mass'},
		{id: '/#current/particulates/surface/level/overlay=duexttau/orthographic', name: 'Dust Extinction'}
	]


	return <div style={{marginTop: "20px", display: "flex", flexDirection: "column", zIndex: '99999', width: '100%', overflow: 'scroll'}}>
			{views.map(item => <span className={`country-item ${globeView === item.id ? 'selected': ''}`} style={{color: 'white'}} onClick={ () => onChoose(item.id) } key={item.id}>{item.name.toUpperCase()}</span>)}
		</div>
}

export default GlobeDropdown