import React from 'react';
import OutsideClickHandler from "react-outside-click-handler";

import cross from './img/cross.svg';

const Guide = ({onClose}) => {
	return (<div id="guide">
		<OutsideClickHandler onOutsideClick={onClose}>
		<div style={{position: 'relative'}}>
		<img src={cross} id="cross-icon" alt="cross-icon" onClick={onClose}/>
		<p>Pandemic Project is a platform where you can find different sorts of data (ecological, geolocational, medical) and use it in the process of composing your musical pieces.</p>
		<p>Here are a few tips on how to use the website.</p>
		
		<p>The “Globe” tab can give you an insight on the Earth’s climate and ecological issues. Choose a type of data from the list, then click on the globe and see the results. </p>
		
		<p>The other tabs, such as Covid-19, Temperature, Air Quality and Weather consist of 3D charts that visualize the statistical data. </p>
		
		<p>To see the data from these charts, enter the name of the country in the field. Also, you can download selected data for the selected country or for all countries by clicking the “download” button.</p>
		<p>You can download data in txt and json formats and use it for your purposes, for exampla MAX \ TouchDesigner Patches. You can save charts in PNG formats as well.</p>
		<p>Switch between the tabs to change the type of data.</p>
		
		<p>Enjoy</p>
		</div>
		</OutsideClickHandler>
	</div>)
}

export default Guide;