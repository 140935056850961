import React from "react";

import data from "./lectors-data";
import "./index.css";

const Lectures = () => {
  return (
    <div
      className="lectures"
      style={{
        color: "white",
        textTransform: "uppercase",
        textAlign: "justify",
        fontWeight: "100",
      }}
    >
      {data.map((item) => (
        <div style={{ position: "relative" }}>
          <div>{item.text}</div>
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            {/* {item.img.map((i) => i)} */}
          </div>
          <div className="videoContainer">{item.video.map((i) => i)}</div>
        </div>
      ))}
    </div>
  );
};

export default Lectures;
