import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { mobileAndTabletCheck } from "./deviceChecker";

export const Navigation = ({ selectedTab, selectTab }) => {
  const location = useLocation();
  const history = useHistory();

  const valid = (item) =>
    (item === "DATA" ||
      item === "TOOLS" ||
      item === "LECTURES" ||
      item === "WORKS") &&
    !mobileAndTabletCheck();
  const classes = (item) => {
    const selected = selectedTab === item ? "selected" : "";

    return `${selected} available`;
  };

  useEffect(() => {
    if (location.pathname === "/main") selectTab(null);
  }, [location]);

  return (
    <nav id="pages-nav">
      {["DATA", "TOOLS", "LECTURES", "WORKS", "MIDI GENERATOR"].map((item) => (
        <span
          className={classes(item)}
          key={item}
          onClick={() => {
            if (item === "MIDI GENERATOR") {
              window.open("http://data-sound.pandemic-media-space.com/make");
              return;
            }
            if (valid(item)) {
              selectTab(item);
            }
          }}
        >
          {item}
        </span>
      ))}
    </nav>
  );
};

export default Navigation;
