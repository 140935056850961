import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import "./App.css";
import axios from "axios";
import Header from "./header";
import Data from "./Data";
import Main from "./Main";
import Osc from "./Osc";
import Lectures from "./Lectures";
import Works from "./Works";
import { saveTxt } from "./savers";
import { dataTabs } from "./dataTabs";

import covid from "./img/COVID.png";
import main from "./img/MAIN.png";

/* eslint-disable no-restricted-globals */

const firebase = require("firebase/app");
require("firebase/database");

const App = () => {
  const [country, setCountry] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [countryWeather, setCountryWeather] = useState(null);
  const [countryCovid, setCountryCovid] = useState(null);
  const [countries, setCountries] = useState([]);
  const history = useHistory();

  const [globeView, setGlobeView] = useState(
    "/#current/wind/surface/level/overlay=temp/orthographic"
  );
  const [tab, setTab] = useState(null);
  const [bgStyles, setBgStyles] = useState({
    backgroundImage: `url("${main}")`,
    animation: "none",
  });
  const [url, setUrl] = useState(`https://earth.nullschool.net${globeView}`);
  const [dataView, setDataView] = useState({ view: "COVID-19", bg: covid });
  const formatLocation = (location) =>
    location
      ? location
          .replace(/[^\w\s]/gi, "")
          .toLowerCase()
          .trim()
          .split(" ")
          .join("-")
      : location;

  useEffect(() => {
    axios
      .get(`https://api.covid19api.com/countries`)
      .then((data) => setCountries(data.data));
  }, []);

  useEffect(() => {
    if (tab === "MAIN") {
      setBgStyles({ backgroundImage: `url("${main}")`, animation: "none" });
      return;
    }

    setBgStyles(
      navigator.userAgent.includes("Firefox")
        ? {
            zIndex: dataView.view === "GLOBE" ? 10 : 0,
            backgroundImage: `url("${dataView.bg}")`,
            animation: "none",
          }
        : { zIndex: dataView.view === "GLOBE" ? 10 : 0 }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!country || countries.length === 0) {
      return;
    }

    const getCovidData = async (country) => {
      const item = countries.find(
        (item) => item.Country.toLowerCase() === country.toLowerCase()
      );
      const items = countries.filter((item) =>
        item.Country.toLowerCase().includes(country.toLowerCase())
      );

      if (!items[0]) {
        return;
      }

      const slug = item.Slug || item[0].Slug;
      let covidData = {};

      const covids = await axios
        .get(
          `https://api.covid19api.com/total/country/${slug}/status/confirmed`
        )
        .then((data) => {
          covidData.confirmed = data.data;

          return axios
            .get(
              `https://api.covid19api.com/total/country/${slug}/status/recovered`
            )
            .then((recovered) => {
              covidData.recovered = recovered.data;

              return axios
                .get(
                  `https://api.covid19api.com/total/country/${slug}/status/deaths`
                )
                .then((death) => {
                  covidData.death = death.data;

                  return covidData;
                });
            });
        });

      setCountryCovid(covids);
    };

    const getCountryData = async (countryName) => {
      const countryRoute = formatLocation(countryName);

      const weatherData = await axios
        .get(`https://pandemic-art-space.firebaseio.com/${countryRoute}.json`)
        .then((data) => data.data);

      setCountryWeather(weatherData);
    };

    getCountryData(country);
    getCovidData(country);
  }, [countries, country]);

  useEffect(() => {
    setUrl(`https://earth.nullschool.net${globeView}`);
  }, [globeView]);

  const getCovidData = async (country) => {
    const item = countries.find(
      (item) => item.Country.toLowerCase() === country.toLowerCase()
    );
    const items = countries.filter((item) =>
      item.Country.toLowerCase().includes(country.toLowerCase())
    );

    if (!items[0]) {
      return;
    }

    const slug = item.Slug || item[0].Slug;

    let covidData = {};

    const covids = await axios
      .get(`https://api.covid19api.com/total/country/${slug}/status/confirmed`)
      .then((data) => {
        covidData.confirmed = data.data;

        return axios
          .get(
            `https://api.covid19api.com/total/country/${slug}/status/recovered`
          )
          .then((recovered) => {
            covidData.recovered = recovered.data;

            return axios
              .get(
                `https://api.covid19api.com/total/country/${slug}/status/deaths`
              )
              .then((death) => {
                covidData.death = death.data;

                return covidData;
              });
          });
      });

    return covids;
  };

  const getCountryData = async (countryName) => {
    const countryRoute = formatLocation(countryName);

    const weatherData = await axios
      .get(`https://pandemic-art-space.firebaseio.com/${countryRoute}.json`)
      .then((data) => data.data);

    return weatherData;
  };

  const downloadData = async () => {
    const [countryName, param] = location.pathname
      .split("/")
      .filter((part) => Boolean(part) && part !== "download");

    const cd = await getCovidData(countryName);
    const wd = await getCountryData(countryName);

    const tab = dataTabs.find((tab) => tab.param === param);

    console.log(tab);

    saveTxt(true, tab, countryName, cd, wd);
    location.pathname = "/main";
  };

  useEffect(() => {
    if (
      countries &&
      countries.length > 0 &&
      location.pathname.includes("download")
    ) {
      downloadData();
    }

    if (!country || countries.length === 0) {
      return;
    }

    const c = getCovidData(country);
    setCountryCovid(c);

    const cd = getCountryData(country);
    setCountryData(cd);
  }, [countries, country]);

  return (
    <div className="App">
      <div id="bg" style={bgStyles} />
      <div className="container">
        <BrowserRouter>
          <Header
            onSelect={(selectedTab) => {
              setTab(selectedTab);
              setCountry("");
              setCountryData([]);
              setCountryWeather(null);
              setCountryCovid(null);
            }}
            selectedTab={tab}
          />

          <Switch>
            <Route
              path="/data"
              render={(props) => (
                <Data
                  {...props}
                  countries={countries.map((item) => item.Country)}
                  chooseCountry={(currentCountry) => {
                    setCountry(currentCountry);
                    setCountryData([]);
                    setCountryWeather(null);
                    setCountryCovid(null);
                  }}
                  chooseGlobeView={setGlobeView}
                  globeView={globeView}
                  data={countryData}
                  countryWeather={countryWeather}
                  countryCovid={countryCovid}
                  country={country}
                  url={url}
                  applyBg={setDataView}
                />
              )}
            />
            <Route path="/tools" component={Osc} />
            <Route path="/lectures" component={Lectures} />
            <Route path="/works" component={Works} />
            <Route path="/main" component={Main} />
            <Route path="/download" component={Main} />
            <Redirect to="/main" />
          </Switch>

          {tab === "DATA" && <Redirect to="/data" />}
          {tab === "TOOLS" && <Redirect to="/tools" />}
          {tab === "LECTURES" && <Redirect to="/lectures" />}
          {tab === "WORKS" && <Redirect to="/works" />}
          {tab === "MAIN" && <Redirect to="/main" />}
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
