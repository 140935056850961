/* eslint-disable no-restricted-globals */
import React from 'react';

import HouseLogo from './img/house-logo.png';
import Forward from './img/forward-logo.png';

const Main = () => {
	const openTab = () => { 
		const win = window.open("https://houseofeurope.org.ua/", '_blank');
		win.focus();
	}

	return <div className="main">
		<h1 className="main-title">PANDEMIC MEDIA SPACE</h1>
		<div className="ticker-wrap">
			<div className="ticker">
				<div>
				<span>Pandemic Media Space Project is a platform where you can find different sorts of data (ecological, geolocational, medical) and use it in the process of composing your musical pieces. Pandemic Media Space Project is supported by the European Union under the House of Europe programme.</span>
				<img src={HouseLogo} alt="House of Europe" id="house-of-europe-logo" onClick={openTab}/>
				<img src={Forward} alt="Moving Forward" id="moving-forward-logo" onClick={openTab}/>
				</div>
			</div>
		</div>
	</div>
}

export default Main;