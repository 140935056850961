import React, { useState } from 'react';
import OutsideClickHandler from "react-outside-click-handler";

import download from "./img/download.svg";

const DownloadButton = ({tab, country, saveJson, isWeatherAvailable, saveTxt, saveChart, isCovidDataEmpty}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const isDataAvailable = tab => 
		(country && tab === 'COVID-19' && !isCovidDataEmpty) || (country && (tab !== 'COVID-19' && tab !== 'GLOBE') && isWeatherAvailable)


	return (<OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
	<div className='download-wrapper'>
	  {(tab !== "GLOBE" && country && isDataAvailable(tab)) && (
		<button
		  className='download'
		  onClick={() => setIsDropdownOpen(true)}
		>
		  <img id='download' src={download} />
		  <span>DOWNLOAD DATA</span>
		</button>
	  )}
	  {tab !== "GLOBE" && country && isDropdownOpen && (
		<>
		<div className='download-dropdown'>
		  {isDataAvailable(tab) && (<div
			className='download-dropdown-item'
			onClick={() => saveJson(true)}
		  >
			DOWNLOAD ONLY {tab} DATA ABOUT {country.toUpperCase()} IN JSON FORMAT
		  </div>)}
		  <div
			className='download-dropdown-item'
			onClick={() => saveJson()}
		  >
			DOWNLOAD ALL DATA ABOUT {country.toUpperCase()} IN JSON FORMAT
		  </div>

		  {isDataAvailable(tab) && (<div
			className='download-dropdown-item'
			onClick={() => saveTxt(true)}
		  >
			DOWNLOAD ONLY {tab} DATA ABOUT {country.toUpperCase()} IN TXT FORMAT
		  </div>)}
		  <div
			className='download-dropdown-item'
			onClick={() => saveTxt()}
		  >
			DOWNLOAD ALL DATA ABOUT {country.toUpperCase()} IN TXT FORMAT
		  </div>
		  {isDataAvailable(tab) && (<div
			className='download-dropdown-item'
			onClick={() => saveChart()}
		  >
			DOWNLOAD {tab} CHART
		  </div>)}
		</div>
		</>
	  )}
	</div>
  </OutsideClickHandler>)
}

export default DownloadButton; 