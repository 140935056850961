export const getDataList = (prop, data) => {
	const {weather, covid} = data;
	let items;

		if(prop === 'covid'){ 
			const confirmed = covid.confirmed.map(item => item.Cases)
			const death = covid.death.map(item => item.Cases)
			const recovered = covid.recovered.map(item => item.Cases)

			items = [ confirmed, death, recovered ]
		 } 
		else if ( prop === 'airQuality'){ 
			const dataItems = Object.values(Object.values(Object.values(Object.values(weather)))[0])
			items = dataItems
				.map(item => {
							if(!item.airQuality || !item.airQuality.aqi){ return }
								return item.airQuality.aqi
							}
						)
				.filter(item => Boolean(item))
		}
		else { 
			const dataItems = Object.values(Object.values(Object.values(Object.values(weather)))[0])
			items = dataItems.map(item => {
							if(!item[prop]){ return }
							if(prop === 'clouds'){ return item[prop].all}
							return item[prop]
						}).filter(item => Boolean(item))
						.map(i => ({[prop]: i}))
		}
	
	return items;
}

export const getAllDataList = (data) => {
	const dataItems = Object.values(Object.values(Object.values(data.weather))[0]);

	const weatherProps = Object.keys(dataItems[dataItems.length - 1])

	return [...weatherProps, 'covid'].map(prop => {
		const i = getDataList(prop, data);

		if(prop === 'covid'){
			return {covid: i}
		}

		if(typeof i[0] === 'object' && i[0] !== null){
			const v = Object.keys(i[0])[0];
			if(v==='wind'){
				return {[v]: { deg: i.map(it => it[v].deg), speed: i.map(it => it[v].speed)}}
			}else{	
				return {[v]: i.map(it => it[v])}
			}
		} else {
			return {[prop]: i}
		}
	})
}
