import React,{ useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect } from 'react';

const CountriesDropdown = ({chooseCountry, countries, country}) => {
	const [searchString,setSearchString] = useState('')
	const [filteredCountries, setFilteredCountries] = useState(countries)
	const [isOpen, setIsOpen] = useState(false)
	const [inputVal, setInputVal] = useState('');

	useEffect(()=>{
		if(country){
			setInputVal(country.toUpperCase())
		}
	}, [])

	const onInput = str => {
		if(!str){
			setInputVal('');
		}

		setSearchString(str)
		setInputVal(str)

		const nextCountries = countries.filter(name => name.toLowerCase().startsWith(str.toLowerCase()))

		setFilteredCountries(nextCountries)
		setIsOpen(true)
	}

	const onChoose = country => {
		chooseCountry(country); 
		setIsOpen(false)
		setInputVal(country.toUpperCase())
	}

	const inputStyles = {
		borderBottom: country ? "2px solid #ff0059" : "2px solid white",
	}



	return <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
	<div className="container" style={{padding: '50px 0 30px 0', position: 'relative'}}>
		<input style={inputStyles} onChange={(event)=> onInput(event.target.value)} id="countries" value={inputVal} placeholder={`Enter country name`.toUpperCase()} autoComplete="off"/>
		{(isOpen && searchString && filteredCountries.length > 0) && (<div style={{position: 'absolute', display: "flex", flexDirection: "column", zIndex: '99999', background: "linear-gradient(179.88deg, rgba(0, 0, 0, 0) 0.11%, rgba(0, 0, 0, 0.7) 101.65%)", borderRadius:"0px 0px 5px 5px", width: '100%', maxHeight: "250px", overflow: 'scroll', filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"}}>
			{filteredCountries.map(country => <span className="country-item" style={{color: 'white'}} onClick={ () => onChoose(country) } key={country}>{country.toUpperCase()}</span>)}
		</div>)}
	</div></OutsideClickHandler>
}

export default CountriesDropdown