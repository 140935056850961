import React from "react";
import download from "./img/download.svg";

import { saveAs } from "file-saver";

const Osc = () => {
  const downloadPatchKostuk = () =>
    saveAs(
      "https://firebasestorage.googleapis.com/v0/b/pandemic-art-space.appspot.com/o/JSON-OSC.maxpat?alt=media&token=53e50cf0-fa90-4414-814b-95aa1275fe83",
      "JSON-SOC.maxpat"
    );

  const downloadPatchPotopalskyi = () =>
    saveAs(
      "https://firebasestorage.googleapis.com/v0/b/pandemic-art-space.appspot.com/o/WEAVID_PROJ.zip?alt=media&token=0c959338-6106-4833-b23c-1287e19f5269",
      "WEAVID_PROJ.zip"
    );

  return (
    <div style={{ marginTop: "-33px" }}>
      <div className="patch-instruction">
        <h3>OSC Template by Ostap Kostiuk</h3>
        <p style={{ marginBottom: "0px" }}>How to use the patch:</p>
        {`
                1. Extract this archive
                2. Run this patch in Max
                3. Choose folder with .json files
                4. Select your data from the list
                5. Start the counter
                6. By changing the value of metro you could change the period of sending the data
                7. Select ip and port in udpsend
                8. Setup your software or equipment, that will be recieving the OSC for listening the proper port.
                This patch only works with numbers.`}
        <p> Made by Ostap Kostiuk 2020</p>
      </div>
      <button className="download" onClick={downloadPatchKostuk}>
        <img id="download" src={download} />
        <span>DOWNLOAD PATCH</span>
      </button>
      <div className="patch-instruction">
        <h3>Weavid API and Weavid Synth by Georgiy Potopalskyi</h3>
        <p style={{ marginBottom: "0px" }}>Instructions:</p>
        {`
                 1. Unpack and run
            `}
      </div>
      <button className="download" onClick={downloadPatchPotopalskyi}>
        <img id="download" src={download} />
        <span>DOWNLOAD PATCH</span>
      </button>
    </div>
  );
};

export default Osc;
